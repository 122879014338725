@import '../theme-bootstrap';

.mac-select-app-nav-formatter {
  font-family: $headline-font-family;
  text-align: center;
  .mm-whats-new-items-mobile {
    #{$ldirection}: 0;
    position: absolute;
    width: 100%;
  }
  .field-menu {
    @include swap_direction(padding, 0 10px 0 20px);
  }
  .menu-container {
    display: none;
  }
  .menu {
    .menu--title i {
      @include fontsize-rem($typesize--14px);
      color: $color--black;
      display: none;
    }
    &--sub {
      color: $color--gray--lighter;
      display: none;
      .menu--title i {
        display: inline;
      }
      .menu-container {
        color: $color--black;
        .menu--title i {
          display: none;
        }
      }
    }
  }
  .menu[menu-sub='0'] {
    display: none;
  }
  .menu[menu-sub='3'] {
    display: block;
  }
  li {
    cursor: pointer;
    display: block;
    @include fontsize-rem($typesize--30px);
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
  a {
    border-bottom: none;
    display: block;
    @include fontsize-rem($typesize--30px);
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
/*  styles for MAC select loyalty Program Overlay **/

@mixin a-color() {
  border-bottom: none;
  color: $color--white;
}

.mac-select-loyalty-tier,
.mac-select-loyalty {
  width: 100%;
  visibility: visible;
  &.hide {
    visibility: hidden;
  }
  background: $color--black--opacity;
  color: $color--white;
  padding: 25px;
  position: absolute;
  z-index: 2;
  text-transform: none;
  &-slider {
    position: absolute;
    right: 15px;
    top: 8px;
    a {
      @include a-color();
      font-size: 25px;
    }
  }
  &-cols {
    @include breakpoint($bp--large-up) {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
    }
  }
  &-image {
    display: none;
    @include breakpoint($bp--large-up) {
      display: inline-block;
      width: 34%;
      img {
        max-width: 150px;
      }
    }
  }
  &-image__upload-link {
    border-bottom: unset;
  }
  &-summary {
    h3 {
      font-size: 20px;
    }
    &__header {
      p {
        margin-bottom: 0;
      }
    }
    &__header,
    &__content {
      display: inline-block;
      text-align: left;
    }
    @include breakpoint($bp--large-up) {
      width: 30%;
      border-left: solid 1px;
      border-right: solid 1px;
      padding: 0 15px;
      h3 {
        font-size: 28px;
        margin-top: 0.3em;
        margin-bottom: 0.1em;
      }
      &__header {
        p {
          line-height: 1;
        }
      }
    }
  }
  &-join {
    @include breakpoint($bp--large-up) {
      width: 34%;
    }
    &__cta {
      @if $fonts_update == false {
        font-size: 12px;
      }
      a {
        color: $color--white;
      }
      @include breakpoint($bp--large-up) {
        background: $color--white;
        letter-spacing: 0;
        line-height: 50px;
        padding-left: 7px;
        margin-bottom: 4%;
        display: inline-block;
        @if $fonts_update == true {
          font-family: $ano-black_regular-font;
          padding: 8px 20px 4px;
        } @else {
          font-family: $ano-bold_regular-font;
          font-size: 17px;
          padding: 0 20px;
        }
        a {
          border-bottom: none;
          color: $color--black;
        }
      }
      @include breakpoint($bp--largest-up) {
        @if $fonts_update == false {
          font-size: 23px;
        }
      }
    }
    &__signin {
      display: none;
      @include breakpoint($bp--large-up) {
        display: block;
        p {
          letter-spacing: 0.05em;
        }
        a {
          @include a-color();
        }
      }
    }
  }
}

@mixin col-mobile() {
  @include breakpoint($bp--large-up) {
    width: auto;
  }
}

.mac-select-loyalty-footer-formatter {
  background: $color--light--black;
  overflow: hidden;
  padding: 10px 0;
  font-size: 17px;
  position: relative;
  z-index: 3;
  .disable-pc {
    display: block;
    @include breakpoint($bp--large-up) {
      display: none;
    }
  }
  .disable-mobile {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
    }
  }
  .col-md-2 {
    width: 50%;
    @include col-mobile();
  }
  .col-md-3 {
    width: 33.33%;
    @include col-mobile();
  }
  .col-md-4 {
    width: 25%;
    @include col-mobile();
  }
  .col-md-5 {
    width: 20%;
    @include col-mobile();
  }
  li {
    font-family: $ano-bold_regular-font;
    text-align: center;
    float: left;
    padding: 0 10px;
    border-left: 2px solid $white;
    @if $fonts_update == true {
      @include h6();
      font-size: 13px;
    }
    @include breakpoint($bp--large-up) {
      font-weight: normal;
      letter-spacing: 0.2px;
      font-size: 17px;
      padding: 0 20px;
      @if $fonts_update == true {
        font-size: 13px;
        letter-spacing: 0;
      }
    }
    &.mac-select-loyalty-footer-links__right {
      @include breakpoint($bp--large-up) {
        float: right;
        border-right: solid 2px $color--white;
        border-left: none;
      }
      &.no-bar {
        border: none;
      }
    }
    &.no-bar {
      border: none;
    }
  }
  a {
    @include a-color();
    line-height: 25px;
    &:hover {
      @include breakpoint($bp--large-up) {
        text-decoration: underline;
      }
    }
  }
}
